function WebAnketa() {
  console.log('Изучение мнения клиентов Alaris Labs (2019 г.)');

  var WebAnketaParams = ["68vkjchj6gqp4d1gc8r6crb6", "Изучение мнения клиентов Alaris Labs (2019 г.)"];
  var script = document.createElement('script');
  script.src = 'http://webanketa.com/direct/js/embed.js';
  document.head.appendChild(script);


  // var WebAnketaParams = ["68vkjchj6gqp4d1gc8r6crb6", "Изучение мнения клиентов Alaris Labs (2019 г.)"];
  // document.write(("<"+"script src='http"+((new RegExp("^https:")).test(document.URL) ? "s" : "")+"://webanketa.com/direct/js/embed.js' type='text/javascript'><"+"/script>"));
}


// Ссылка:
//   http://www.alarislabs.com/ru/customer-survey

    // Содержание страницы:
  // <script type="text/javascript">



// let WebAnketaParams = ["68vkjchj6gqp4d1gc8r6crb6", "Изучение мнения клиентов Alaris Labs (2019 г.)"];
// document.write(unescape("<"+"script src='http"+((new RegExp("^https:")).test(document.URL) ? "s" : "")+"://webanketa.com/direct/js/embed.js' type='text/javascript'><"+"/script>"));
// </script>

// <noscript>
// <a href="http://webanketa.com/forms/68vkjchj6gqp4d1gc8r6crb6/" target="_blank">Изучение мнения клиентов Alaris Labs (2019 г.)</a>
// </noscript>


// Ссылка:
//   http://www.alarislabs.com/customer-survey


//     // Содержание страницы:
//   <script type="text/javascript">var WebAnketaParams = ["68vkjchj6rqp8d9pcgv3ed31", "Customer Satisfaction Survey (2019)"];
// document.write(unescape("<"+"script src='http"+((new RegExp("^https:")).test(document.URL) ? "s" : "")+"://webanketa.com/direct/js/embed.js' type='text/javascript'><"+"/script>"));
// </script><noscript><a href="http://webanketa.com/forms/68vkjchj6rqp8d9pcgv3ed31/" target="_blank">Customer Satisfaction Survey (2019)</a></noscript>
